<template>
  <b-card-code
    no-body
    title="List Campaign"
  >
    <ListOrder
      :result="result"
      :is-loading="isLoading"
      :get-data="getData"
    />
  </b-card-code>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import ListOrder from '@/components/Campaign/ListCampaign.vue'

export default {
  title() {
    return 'List Campaign'
  },
  components: {
    BCardCode,
    ListOrder,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      isLoading: true,
      result: {},
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.isLoading = true

      this.$http.get('/admin/ads-campaign')
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
.table > tbody > tr > td {
  max-width: 300px;
}
</style>
